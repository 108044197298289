import React, {useState} from "react";
import {UserListViewModel} from "../ViewModels/UserListViewModel";
import {Input} from "reactstrap";
import {BranchLookupBranchViewModel} from "../ViewModels/BranchLookupBranchViewModel";
import {toast} from "react-toastify";
import {formatPhoneNumber} from "../../../../Utilities/FormatPhoneNumber";

interface IUserAddRowProps {
    showAdd: () => void;
    handleAdd: (viewModelToAdd: UserListViewModel) => void;
    branchData: BranchLookupBranchViewModel[];
}

export const UserAddRow = ({handleAdd, branchData, showAdd}: IUserAddRowProps) => {
    const initialState = {
        userId: "",
        name: "",
        email: "",
        branch: {
            name: "",
            branchId: 0
        },
        phone: ""
    }
    const [newRow, setNewRow] = useState(initialState)
    const [isDropwdownOpen, setIsDropDownOpen] = useState(false);
    
    const handleChange = (e: any) => {
        setNewRow({
            ...newRow,
            [e.target.name]: e.target.value
        });
    }
    
    const handlePhoneChange = (e: any) => {
        let number = formatPhoneNumber(e.target.value);
        setNewRow({
            ...newRow,
            phone: number
        });
    }

    const handleDropDownClick = (e: any) => {
        //This keeps it from suddenly reading as a null value.
        e.persist();
        setNewRow(prevState => {
            return {
                ...newRow,
                branch: {
                    name: e.target.name,
                    branchId: e.target.value
                }
            }
        })
    }

    const toggle = () => {
        setIsDropDownOpen(!isDropwdownOpen);
    }

    const addNewValue = () => {
        if(newRow.name == "") return toast("Name is empty", {type: "error"});
        if(newRow.email == "") return toast("Email is empty", {type: "error"});
        //there are 14 character in a formatted phone number
        if(newRow.phone == "") return toast("Phone Number is empty", {type: "error"});
        if(newRow.phone.length < 14) return toast("Phone Number is not complete", {type: "error"});
        handleAdd({
            userId: newRow.userId,
            name: newRow.name,
            email: newRow.email,
            roles: "subscription",
            branchName: newRow.branch.name,
            branchId: newRow.branch.branchId,
            phone: newRow.phone
        })
        setNewRow(prevState=> {
            return ({
                userId: "",
                name: "",
                email: "",
                branch: {
                    name: "",
                    branchId: 0
                },
                phone: ""
            })
        })
    }
    
    const populateBranches = () => {
        return(
            <Input type="select" required onChange={((e: any) => handleDropDownClick(e))} value={newRow.branch.branchId}>
                {branchData.map((data, index: number) => {
                    return (
                        <option key={index} value={data.branchId}>{data.branchName}</option>
                    )
                })}
            </Input>
        )
    }
    
    return (
        <>
            <tr key={0}>
                <td>
                    <div className="input-group">
                        <input className="form-control" type="text" value={newRow.name} placeholder="Name" name="name" onChange={handleChange} required></input>
                    </div>
                </td>
                <td >
                    <div className="input-group">
                        <input className="form-control" type="text" placeholder="Email" value={newRow.email} name="email" onChange={handleChange} required></input>
                    </div>
                </td>
                <td >
                    <div className="input-group">
                        <input className="form-control" type="tel" placeholder="(xxx) xxx-xxxx" value={newRow.phone} name="phone" onChange={handlePhoneChange} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required></input>
                    </div>
                </td>
                <td>
                    {populateBranches()}
                </td>
                <td className="align-middle text-center">
                    <a className="btn btn-secondary text-white" type="submit" onClick={addNewValue}>Add</a>
                    <a className="btn btn-secondary text-white ml-4" onClick={showAdd}>Cancel</a>
                </td>
            </tr>
        </>
    )
}