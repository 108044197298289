const AuthenticationService = {
  login,
  logout,
  canAccess,

  get currentUser() {
    var cookie = getCookie("CurrentUser");

    if (cookie == "") return;
    let localUser = JSON.parse(cookie);

    // token is in seconds, javascript is in milliseconds -- so multiply by 1000.
    if (new Date(localUser.Expires * 1000) < Date.now()) {
      logout();
      window.redirect.href = "#/user/login";
    }
    return localUser;
  },
};

function canAccess(role) {
  const roles = this.currentUser.Roles;
  if (roles.length == 0) return false;

  const filteredRole = roles.filter(x => x.Role == role);
  if (filteredRole.length == 0) return false;

  // first filteredRole:
  return filteredRole[0].CanAccess;
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function delete_cookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// This method does not work because microsoft MFA.  We need to "redirect" to microsoft instead.
function login() {
  var redirect = "https://rmrquote.ion247.com/";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    var redirect = "https://localhost:5300/";
  }
  window.location.href =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?" +
    "client_id=c40e6721-5f44-40c3-87bc-9cbc901d55c7&" +
    "scope=openid%20user.read%20profile%20email&" +
    "response_type=id_token%20token&nonce=xsy&" +
    "response_mode=form_post&" +
    "redirect_uri=" +
    redirect +
    "api/user/microsoftLogin";
}

function logout() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  delete_cookie("CurrentUser");
  return null;
}

export default AuthenticationService;
